import bannerImage from "../../Images/bannerImageSv.png";

export const svTranslation = {
 navbar: {
  home: "Hem",
  about: "Om Oss",
  contact: "Kontakta Oss",
  faq: "Vanliga frågor",
 },
 heroSection: {
  heading: "Erfaren, Personlig IT",
  subheading: "Tjänster",
  description:
   "Få expert IT-support skräddarsydd just för dig. Från felsökning till optimering, vi har dig täckt.",
  buttonLabel: "Boka en Gratis Konsultation",
  count: {
   employee: "Anställda",
   clients: { c1: "Över hela världen", c2: "Kunder" },
   projects: { p1: "Levererade", p2: "Projekt" },
   experience: { e1: "År av", e2: "Erfarenhet" },
  },
 },
 secondSection: {
  heading: "Stärka Digital Framgång: Owision AB",
  description:
   "Owision AB är ett ledande företag inom web- och mobilapp-utveckling baserat i Stockholm, Sverige. Vi följer motto 'Kund Först' och tillämpar innovation i varje steg av din app-utvecklingsresa. Startade 2018, har Owision möjliggjort lanseringen av ledande web- och mobil-lösningar som överraskade den digitala världen.",
  buttonLabel: "Utforska Mer",
 },
 thirdSection: {
  one: "COACHA UTVECKLINGSTEAM OM PROGRAMVARUKVALITETS-SÄKERHET",
  two: "IDENTIFIERA VAD OCH HUR MAN GÖR TESTAUTOMATION",
  three:
   "IDENTIFIERING AV DE BÄSTA VERKTYGEN FÖR AUTOMATION AV PROGRAMVARUPROJEKT",
  four: "UTARBETA PROCESSER FÖR UAT",
  five: "TESTRESULTAT RAPPORTERING",
  bannerImage,
 },
 servicesOffered: {
  heading: "Vad Vi Levererar",
  services: {
   s1: {
    title: "Hanterade Tjänster",
    description:
     "Frigör dina interna resurser för att fokusera på verksamheten genom att låta oss hantera dagliga supporttjänster, förvaltning och övervakning av din IT.",
   },
   s2: {
    title: "SAP-implementering och stöd",
    description:
     "Vi specialiserar oss på ERP-konsultation och SAP-praktik. Vi erbjuder skräddarsydda, kostnadseffektiva lösningar för att underhålla, stödja och förbättra befintliga SAP-applikationer med expertis inom branschspecifik programvara och bästa praxis.",
    button: "läs mer",
   },
   s3: {
    title: "Generativ AI",
    description:
     "Gen AI Service specialiserar sig på att skapa skräddarsydda molnlösningar, exakt anpassade till dina affärsbehov och mål, för att maximera prestanda och effektivitet.",
   },
   s4: {
    title: "Webbutveckling",
    description:
     "Våra webbutvecklingstjänster kan hjälpa dig att etablera en imponerande online-närvaro och nå din målgrupp effektivt.",
   },
   s5: {
    title: "Mobil Utveckling",
    description:
     "Vi kan hjälpa dig att skapa en anpassad mobilapp som stämmer överens med ditt varumärke och dina mål, med expertis inom olika mobilplattformar.",
   },
   s6: {
    title: "Testtjänst",
    description:
     "Med vår kompetens inom testtjänster är vi engagerade i att hjälpa dig att identifiera och implementera de mest effektiva testlösningarna som är anpassade till dina specifika krav och mål.",
   },
   s7: {
    title: "IT Konsult & Rådgivning",
    description:
     "Rätt teknologi, implementerad på rätt sätt, ordentligt hanterad och övervakad, kan leda till betydande tillväxtvinster.",
   },
  },
 },
 footer: {
  heading: {
   about: "Om Oss",
   link1: "Hem",
  },
  quickLink: {
   heading: "Snabblänkar",
   link1: "Vanliga frågor",
   link2: "Sekretesspolicy",
  },
  contact: {
   heading: "Kontakta Oss",
   email: "info@owision.com",
   phone: "+46-84527113",
  },
  getintouch: "Kom i Kontakt",
  copyright: "Upphovsrätt © 2024 Owision - Alla Rättigheter Förbehållna.",
 },
 about: {
  heading: "Ägnad åt att leverera excellens",
  subheading: "Alla har en berättelse. Här är vår.",
  ourStory: {
   s1: "Välkommen till Owision, där vi utmärker oss i att skapa banbrytande mjukvarulösningar för transformativa digitala upplevelser. Vårt engagemang för excellens driver oss att pressa tekniska gränser.",
   s2: "Vår kundcentrerade strategi skiljer oss åt. Vi känner igen varje företags unika egenskaper och fördjupar oss i våra kunders behov, utmaningar och mål. Denna skräddarsydda strategi gör att vi kan skapa anpassade mjukvarulösningar för maximal effektivitet och påverkan.",
   s3: "Vi har expertis inom Fintech, Telekom och Spelindustrin. Oavsett om du är en startup som siktar på skalbarhet, ett företag i digital transformation eller något däremellan, har vi kunskapen och erfarenheten att förverkliga din vision.",
   s4: "Owision är dedikerat till att vårda långvariga partnerskap. Utöver att leverera mjukvara strävar vi efter att vara din strategiska allierade på vägen till framgång. Utforska möjligheterna med Owision, där innovation möter excellens.",
   s5: "Owision är ett team av enkla, ärliga och intelligenta människor som brinner för att identifiera möjligheter att göra ett bra företag fantastiskt! Vi värdesätter relationer med våra kunder, anställda och leverantörer som vår måttstock för framgång.",
  },
  mission: {
   heading: "Vårt Uppdrag",
   p: "På Owision drivs vi av ett kärnuppdrag - att stärka företag av alla storlekar med de teknologiska verktyg de behöver för att blomstra i dagens dynamiska marknad. Vårt team av skickliga och erfarna utvecklare, designers och strateger samarbetar sömlöst för att skapa mjukvarulösningar som inte bara möter utan överträffar våra kunders förväntningar.",
  },
 },

 contactUs: {
  heading: "Redo att",
  span1: "Ansluta, Samarbeta och Skapa Excellens?",
  span2: "Vi är bara ett klick bort!",
  contactDetails: {
   call: {
    heading: "Ring Oss",
    p: "Mån till Fre (10:00 – 19:00)",
    button: "+46-84527113",
   },
   email: {
    heading: "Skicka ett e-postmeddelande",
    p: "Få svar inom 1 arbetsdag.",
    button: "info@owision.com",
   },
   meeting: {
    heading: "Boka ett möte",
    p: "30 minuters online-samtal",
    button: "Boka ett möte",
   },
  },
  form: {
   heading: "Fyll bara i dessa uppgifter",
   subheading: "Vi kommer att kontakta dig inom 24 timmar.",
   name: { title: "Namn", placeholder: "John Doe" },
   email: { title: "E-postadress", placeholder: "Din e-postadress" },
   phone: {
    title: "Ange ditt telefonnummer (Ange även landskod)",
    placeholder: "Tel: +1 718 303 2844",
   },
   msg: {
    title: "Skriv ditt meddelande nedan",
    placeholder: "Ditt meddelande",
   },
   button: "SKICKA",
  },
 },
 faqHeading: "Vanliga Frågor och Svar",
 faq: [
  {
   question: "Vilka tjänster erbjuder ert mjukvaruutvecklingsföretag?",
   answer:
    "Vi erbjuder ett omfattande utbud av tjänster, inklusive anpassad mjukvaruutveckling, mobilapputveckling, webbplatsutveckling och mjukvarukonsulttjänster.",
  },
  {
   question: "Hur erfaren är ert utvecklingsteam?",
   answer:
    "Vårt utvecklingsteam består av högt kvalificerade och erfarna yrkespersoner med expertis inom olika teknologier och branscher.",
  },

  {
   question: "Kan ni arbeta med specifika teknologier eller ramverk?",
   answer:
    "Ja, vi är skickliga på en mängd olika teknologier och ramverk. Vårt team är anpassningsbart och kan arbeta med de teknologier som bäst passar era projektkrav.",
  },
  {
   question: "Vilka branscher specialiserar ni er på?",
   answer:
    "Vi har erfarenhet av att arbeta inom olika branscher, inklusive hälsovård, finans, e-handel, utbildning och mer.",
  },
  {
   question: "Hur säkerställer ni säkerheten för vårt projekt?",
   answer:
    "Säkerhet är en hög prioritet för oss. Vi implementerar branschens bästa praxis, genomför regelbundna säkerhetsrevisioner och följer strikta dataskyddsåtgärder.",
  },

  {
   question: "Vad är den typiska utvecklingsprocessen som ert företag följer?",
   answer:
    "Vår utvecklingsprocess innefattar detaljerad projektanalys, design, utveckling, testning, driftsättning och löpande support. Vi följer agila metoder för flexibilitet och kundinvolvering.",
  },
  {
   question:
    "Kan ni hantera både små projekt och storskaliga företagslösningar?",
   answer:
    "Absolut. Vi tillgodoser projekt av alla storlekar och ser till att våra lösningar överensstämmer med klienternas specifika behov och mål.",
  },
  {
   question: "Hur hanterar ni projektledning och kommunikation?",
   answer:
    "Vi använder robusta projektledningsverktyg och upprätthåller transparanta kommunikationskanaler för att hålla klienterna informerade om projektets framsteg.",
  },
  {
   question:
    "Vad är den genomsnittliga tidslinjen för att slutföra ett mjukvaruprojekt?",
   answer:
    "Projektets tidslinje varierar beroende på komplexitet och omfattning. Vi tillhandahåller detaljerade tidslinjer under den inledande konsultationen.",
  },
  {
   question:
    "Hur hanterar ni förändringar eller uppdateringar under utvecklingsprocessen?",
   answer:
    "Vi välkomnar feedback och förändringar under hela utvecklingsprocessen. Vi följer en iterativ metod och säkerställer flexibilitet för att tillgodose kundernas krav.",
  },

  {
   question:
    "Erbjuder ni löpande underhåll och support efter att projektet är slutfört?",
   answer:
    "Ja, vi tillhandahåller löpande underhåll och support för att säkerställa att er mjukvara fungerar smidigt och att eventuella problem snabbt åtgärdas.",
  },
  {
   question:
    "Kan ni hjälpa till med att integrera den nya mjukvaran med befintliga system?",
   answer:
    "Absolut. Vi har expertis inom sömlös integration för att säkerställa att den nya mjukvaran kompletterar och förbättrar era befintliga system.",
  },
  {
   question: "Hur säkerställer ni mjukvarans kvalitet?",
   answer:
    "Kvalitetssäkring är en integrerad del av vår utvecklingsprocess. Vi genomför noggranna tester i varje steg för att identifiera och åtgärda eventuella problem omedelbart.",
  },

  {
   question:
    "Vad händer om vi stöter på problem efter att projektet är slutfört?",
   answer:
    "Vi erbjuder support efter lansering för att åtgärda eventuella problem. Vårt team är engagerat i att säkerställa den långsiktiga framgången för er mjukvara.",
  },
  {
   question:
    "Kan ni tillhandahålla referenser eller fallstudier av era tidigare projekt?",
   answer:
    "Självklart. Vi kan tillhandahålla referenser och fallstudier på begäran för att visa våra framgångsrika samarbeten.",
  },
  {
   question: "Hur hanterar ni immateriella rättigheter och sekretess?",
   answer:
    "Vi prioriterar klientsekretess och följer strikta åtgärder för skydd av immateriella rättigheter. Vi är öppna för att underteckna sekretessavtal (NDA) vid behov.",
  },

  {
   question: "Vad är er prismodell?",
   answer:
    "Vår prismodell är skräddarsydd efter projektets specifika krav. Vi tillhandahåller transparenta och detaljerade kostnadsuppskattningar under den inledande konsultationen.",
  },
  {
   question: "Hur hanterar ni förändringar i projektets omfattning eller krav?",
   answer:
    "Vi förstår att projektets omfattning kan utvecklas. Vi arbetar nära klienterna för att tillgodose förändringar och justera tidslinjer och kostnader därefter.",
  },
  {
   question: "Vad är ert tillvägagångssätt för användarupplevelse (UX) design?",
   answer:
    "Vi prioriterar användarcentrerad design och säkerställer att våra lösningar ger en intuitiv och trevlig upplevelse för slutanvändarna.",
  },
  {
   question: "Hur kan vi komma igång med ett projekt?",
   answer:
    "Kontakta oss helt enkelt via vår webbplats eller kontakta oss direkt. Vi kommer att boka en inledande konsultation för att diskutera era projektmål, krav och nästa steg.",
  },
 ],
 sap: {
  intro: {
   heading: "Introduktion till SAP",
   desc:
    "SAP är en global ledare inom tillhandahållande av företagsprogramvarulösningar, utformade för att underlätta affärsverksamheter och främja tillväxt inom olika sektorer. Med sitt omfattande utbud av tjänster möter SAP ett brett spektrum av affärsbehov genom sina moduler inom Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Supply Chain Management (SCM), Human Resources (HR) och mer. Oavsett om du är ett litet företag eller ett stort företag, levererar SAP skräddarsydda lösningar som hjälper till att effektivisera dina processer, förbättra kundrelationer och utnyttja datadrivna insikter för strategiskt beslutsfattande.",
  },
  module: {
   heading: "Viktiga moduler i SAP",
   erp: {
    span: "SAP ERP :",
    li: "Integrerar kärnaffärsfunktioner som ekonomi, försäljning, inköp och tillverkning i en enhetlig plattform, vilket förbättrar realtidsinsyn och stöder effektivt beslutsfattande.",
   },
   crm: {
    span: "SAP CRM",
    li: "Förbättrar kundengagemang och försäljning genom att hantera interaktioner över olika kanaler, vilket förbättrar kundnöjdheten och affärsresultaten.",
   },
   scm: {
    span: "SAP SCM :",
    li: "Optimerar leveranskedjeoperationer med verktyg för effektiv efterfråge- och försörjningsplanering, lagerhantering och logistik.",
   },
   hr: {
    span: "SAP HR :",
    li: "Hantera HR-processer inklusive löner, talanghantering och personalplanering för att attrahera, utveckla och behålla talanger.",
   },
   bi: {
    span: "SAP BI :",
    li: "Omvandlar data till handlingsbara insikter genom robust rapportering, analys och visualiseringsverktyg som underlättar omfattande affärsanalys.",
   },
   more: {
    span: "Och många fler :",
    li: "Inklusive SAP HCM, SAP SRM, SAP EWM, SAP PLM och SAP EHS, som alla adresserar specifika affärsbehov och förbättrar operationell effektivitet.",
   },
  },
  plan: {
   heading: "Vår SAP-implementeringsplan",
   desc:
    "Vi inleder varje SAP-projekt med en noggrann bedömning av dina affärskrav, mål och nuvarande system för att anpassa vårt tillvägagångssätt. Vår implementeringsprocess inkluderar:",
   li1: "Detaljerad projektplanering med tydliga milstolpar och tidslinjer.",
   li2: "Omfattande teambeställning med konsulter, utvecklare och intressenter.",
   li3: "Anpassning av SAP-funktioner för att möta unika affärskrav.",
   li4: "Datamigrering från äldre system till SAP-plattformen.",
   li5: "Noggrann testning för att säkerställa funktionalitet och prestanda.",
   li6: "Användarutbildning och support för att maximera systemanvändning och effektivitet.",
   li7: "Fasindelade lanseringar för att minimera operationella störningar.",
  },
  implement: {
   heading: "Varför välja oss för din SAP-implementering?",
   p: "Vår djupa expertis inom SAP-teknologier och en dokumenterad framgångshistorik av lyckade implementeringar gör oss till den idealiska partnern för ditt SAP-projekt. Vi prioriterar samarbete med kunder och är dedikerade till att omvandla dina affärsprocesser genom innovativa SAP-lösningar. Genom att välja oss får du fördelar som:",
   li1: "Ett erfaret team av experter som är engagerade i ditt projekt från början till slut.",
   li2: "Skräddarsydda lösningar som är utformade för att möta dina unika affärsbehov.",
   li3: "Ett fokus på att leverera konkreta affärsresultat och en hög avkastning på investeringen.",
   li4: "Kontinuerligt stöd och optimering av dina SAP-system efter implementering.",
  },
  whySpa: {
   heading: "Varför SAP?",
   desc:
    "SAP's oöverträffade kombination av omfattande kapabiliteter och innovativa lösningar gör det till det föredragna valet för företag som vill framtidssäkra sina verksamheter. Med SAP's breda utbud av moduler kan företag effektivisera verksamheten, främja digital transformation och bibehålla en konkurrensfördel i en dynamisk marknadslandskap.",
  },
  competitors: {
   heading: "SAP's konkurrenter",
   desc:
    "SAP konkurrerar med flera andra stora aktörer på marknaden för företagsprogramvara, inklusive Oracle, Microsoft Dynamics 365 och Salesforce, som alla erbjuder unika styrkor och fokusområden. Valet mellan SAP och dess konkurrenter beror på specifika affärsbehov, branschkrav och budgetöverväganden.",
  },
  resolution: {
   heading: "Lösning",
   desc:
    "Dra nytta av SAP's robusta uppsättning av företagsprogramvarulösningar för att driva effektivitet, tillväxt och framgång i din organisation. Med vår expertvägledning och omfattande stöd hjälper vi dig att navigera i din SAP-resa för att uppnå optimala resultat och en betydande avkastning på investeringen. Omfamna kraften i SAP med oss och förvandla ditt företag till en mer agil, insiktsfull och effektiv verksamhet.",
  },
 },
 privacy: {
  heading: "Integritetspolicy",
  sub: "för Owision AB",
  p1: "Denna integritetspolicy gäller Owision AB och dess webbplats:",
  p2: "Vilka personuppgifter vi samlar in och varför vi samlar in dem",
  cookies: {
   heading: "Cookies",
   desc:
    "En 'cookie' är en liten textfil som lagras på en dator för att föra register. Vi använder cookies för att analysera trender, webbplatsadministration, spåra användarrörelser och för att samla in demografisk information från vår användarbas som helhet. Vissa cookies finns kvar på din dator tills du tar bort dem. Andra, såsom sessions-ID-cookies, upphör att gälla när du stänger din webbläsare. Du kan ställa in din webbläsare för att försöka avvisa cookies och du kan fortfarande använda tjänsten, men vissa funktioner i tjänsten kanske inte är tillgängliga om din webbläsare inte accepterar cookies. Den här webbplatsen använder Google Analytics som samlar in, lagrar och analyserar webbanvändningsdata för att förstå hur människor använder webbplatsen.",
  },
  pixels: {
   heading: "Pixlar",
   desc:
    "'Pixlar' är små grafikbilder med en unik identifierare som används för att spåra webbanvändares rörelser på nätet. Till skillnad från cookies, som lagras på en dators hårddisk, är pixlar små grafikbilder som är ungefär lika stora som en punkt i slutet av en mening som är osynligt inbäddade på webbsidor eller i HTML-baserade e-postmeddelanden. Våra tredjepartsanalysleverantörer kan placera pixlar på webbplatsen som spårar vilka andra webbplatser du besöker (både före och efter att du besöker webbplatsen). Våra tredjepartsleverantörer använder informationen som erhållits från pixlar för att hjälpa oss att förbättra vår verksamhet och tjänsten. Vi kontrollerar inte användningen av pixlar av tredje parter.",
  },
  thirdParty: {
   heading: "Tredjepartsanalysleverantörer",
   desc:
    "Vi använder tredje parter för att hjälpa oss att driva och förbättra tjänsten. När vi gör detta kan vi dela med dessa tredje parter icke-personligt identifierbar information om användares användning av tjänsten. Dessa tredjepartsleverantörer använder också cookies. Vi använder tredjepartsanalysleverantörer, såsom Google Analytics, för att ge oss allmän demografisk och intressenivåinformation om våra användare och för att hjälpa till att skapa en bättre användarupplevelse. Vi kontrollerar inte information som samlas in av tredje parter och ansvarar inte för deras användning av den informationen.",
  },
  embedded: {
   heading: "Inbäddat innehåll från andra webbplatser",
   desc:
    "Artiklar på denna webbplats kan innehålla inbäddat innehåll (t.ex. videor, bilder, artiklar, etc.). Inbäddat innehåll från andra webbplatser beter sig på exakt samma sätt som om besökaren hade besökt den andra webbplatsen. Dessa webbplatser kan samla in data om dig, använda cookies, bädda in ytterligare spårning från tredje part och övervaka din interaktion med det inbäddade innehållet, inklusive att spåra din interaktion med det inbäddade innehållet om du har ett konto och är inloggad på den webbplatsen.",
  },
  analytics: {
   heading: "Analys",
   desc:
    "Denna webbplats använder Google Analytics som samlar in, lagrar och analyserar webbanvändningsdata för att hjälpa till att förstå hur människor använder webbplatsen. Google Analytics använder cookies och pixlar för att samla in demografisk och intressenivåinformation samt användningsinformation från användare som besöker tjänsten, inklusive men inte begränsat till information om vilka sidor där användare går in och lämnar tjänsten och vilka sidor användare visar på tjänsten, tid spenderad, webbläsare, operativsystem och IP-adress. Cookies och pixlar tillåter Google att känna igen en användare när en användare besöker tjänsten och när användaren besöker andra webbplatser. Google använder informationen som samlas in från tjänsten och andra webbplatser för att dela med oss och andra webbplatsoperatörer information om användare inklusive, men inte begränsat till, åldersgrupp, kön, geografiska områden, allmänna intressen och detaljer om enheter som används för att besöka webbplatser och köpa föremål. Vi vidtar rimliga åtgärder för att förhindra länkar mellan information som vi får från Google med några av dina personligt identifierbara uppgifter. För mer information om Googles användning av cookies och insamling och användning av information, se ",
   link: "Google integritetspolicy",
  },
  p3: "Vilka rättigheter du har över dina data",
  p4: "Om du har ett konto på den här webbplatsen, eller har lämnat kommentarer, kan du begära att få en exporterad fil med de personuppgifter vi har om dig, inklusive alla uppgifter du har lämnat till oss. Du kan också begära att vi raderar alla personuppgifter vi har om dig. Detta inkluderar inte några uppgifter som vi är skyldiga att behålla för administrativa, juridiska eller säkerhetsändamål.",
  p5: "Besökarens data kan kontrolleras via en automatisk spårningstjänst och skickas till tredjepartsprodukter.",
 },
};
