import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUs";
import FAQ from "./Pages/FAQ";

import PrivacyPolicy from "./Pages/PrivacyPolicy";

import ContactUs from "./Pages/ContactUs";
import Sapcotent from "./Pages/Sapcotent";


function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route element={<HomePage />} path="/" />
            <Route element={<AboutUsPage />} path="/about-us" />
            <Route element={<FAQ />} path="/faq" />
            {/* <Route element={<TermAndCondition />} path="/terms-condition" /> */}
            <Route element={<PrivacyPolicy />} path="/privacy-policy" />
            <Route element={<ContactUs />} path="/contact-us" />
            <Route element={<Sapcotent />} path="/sapcontent" />



            {/* <Route element={<Gdpr />} path="/gdpr" /> */}

        </Routes>
    </BrowserRouter>
  );
}

export default App;
