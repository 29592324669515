import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value); 
    };

    return (
        <div className="language-selector">
            <select onChange={handleLanguageChange} value={i18n.language}>
                <option value="en">English</option>
                <option value="sv">Swedish</option>
                <option value="de">German</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
