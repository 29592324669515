import { useEffect, useState } from "react";
import Logo from "../Images/owisionlogo.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const Navbar = () => {
    
    const { t } = useTranslation();

    const [openMenu , setOpenMenu] = useState(false)
    const [activeLink, setActiveLink] = useState("/");

    useEffect(() => {
        setActiveLink(window.location.pathname);
    }, []);

    return(
        <div className="navbar_container">
            <div className="navbar_wrapper">
                <div className="navbar_logo">
                   <a href="/"> <img alt="Owision AB Logo" src={Logo} /></a>
                </div>
                <div className="menu_toggle">
                    <div className="menu_link">
                        <a href="/" className={
                            activeLink === "/" ? "active_blue":""
                        } >{t('navbar.home') }</a>
                        <a href="/about-us" className={
                            activeLink === "/about-us" ? "active_blue":""
                        }>{t('navbar.about')}</a>
                        <a href="/contact-us" className={
                            activeLink === "/contact-us" ? "active_blue":""
                        }>{t('navbar.contact')}</a>
                        <a href="/faq" className={
                            activeLink === "/faq" ? "active_blue":""
                        }>{t('navbar.faq')}</a>
                        <LanguageSelector />

                    </div>
                    <svg onClick={()=>setOpenMenu(true)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#303F7A" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                    </svg>
                </div>
                {/* <div className="empty_space"></div> */}

                {
                    openMenu && 
                    <div className="full_screen_menu">
                            <div className="navbar_inner_wrapper"  style={{display:"flex" , justifyContent:"flex-end"}}>
                                <svg onClick={()=>setOpenMenu(false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </div>
                            <div className="menu_link_mobile">
                                <a href="/">{t('navbar.home')}</a>
                                <a href="/about-us">{t('navbar.about')}</a>
                                <a href="/contact-us">{t('navbar.contact')}</a>
                                <a href="/faq">{t('navbar.faq')}</a>
                                <LanguageSelector />
                            </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Navbar;