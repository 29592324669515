import bannerImage from "../../Images/bannerImageDe.png";

export const deTranslation = {
 navbar: {
  home: "Startseite",
  about: "Über Uns",
  contact: "Kontakt",
  faq: "Häufig gestellte Fragen",
 },
 heroSection: {
  heading: "Erfahrene, Personalisierte IT",
  subheading: "Dienstleistungen",
  description:
   "Erhalten Sie Experten-IT-Support, der speziell auf Sie zugeschnitten ist. Von der Fehlersuche bis zur Optimierung, wir haben Sie abgedeckt.",
  buttonLabel: "Kostenlose Beratung vereinbaren",
  count: {
   employee: "Mitarbeiter",
   clients: { c1: "Weltweit", c2: "Kunden" },
   projects: { p1: "Gelieferte", p2: "Projekte" },
   experience: { e1: "Jahre an", e2: "Erfahrung" },
  },
 },
 secondSection: {
  heading: "Digitale Erfolge stärken: Owision AB",
  description:
   "Owision AB ist ein führendes Unternehmen für Web- und Mobile-App-Entwicklung mit Sitz in Stockholm, Schweden. Wir folgen dem Motto 'Kunde zuerst' und setzen Innovation in jeder Phase Ihrer App-Entwicklung um. Gegründet im Jahr 2018, hat Owision die Einführung führender Web- und Mobil-Lösungen ermöglicht, die die digitale Welt überrascht haben.",
  buttonLabel: "Mehr Erfahren",
 },
 thirdSection: {
  one: "TRAINING VON ENTWICKLUNGSTEAMS ZUR SOFTWAREQUALITÄTSSICHERUNG",
  two: "IDENTIFIZIEREN, WAS UND WIE TESTAUTOMATION",
  three: "IDENTIFIZIERUNG DER BESTEN WERKZEUGE FÜR SOFTWARE-PROJEKTAUTOMATION",
  four: "AUFZEICHNUNG DER PROZESSE FÜR UAT",
  five: "TESTERGEBNISBERICHTERSTATTUNG",
  bannerImage,
 },
 servicesOffered: {
  heading: "Was Wir Liefern",
  services: {
   s1: {
    title: "Managed Services",
    description:
     "Entlasten Sie Ihre internen Ressourcen, um sich auf das Geschäft zu konzentrieren, indem Sie uns die tägliche Unterstützung, Verwaltung und Überwachung Ihrer IT überlassen.",
   },
   s2: {
    title: "SAP-Implementierung und Unterstützung",
    description:
     "Wir sind auf ERP-Beratung und SAP-Praxis spezialisiert. Wir bieten maßgeschneiderte, kosteneffiziente Lösungen zur Wartung, Unterstützung und Verbesserung bestehender SAP-Anwendungen mit Expertise in branchenspezifischer Software und Best Practices.",
    button: "läs mer",
   },
   s3: {
    title: "Generative KI",
    description:
     "Der Gen AI Service spezialisiert sich auf maßgeschneiderte Cloud-Lösungen, die genau auf Ihre geschäftlichen Bedürfnisse und Ziele zugeschnitten sind, um Leistung und Effizienz zu maximieren.",
   },
   s4: {
    title: "Webentwicklung",
    description:
     "Unsere Webentwicklungsdienste können Ihnen helfen, eine wirkungsvolle Online-Präsenz aufzubauen und Ihre Zielgruppe effektiv zu erreichen.",
   },
   s5: {
    title: "Mobile Entwicklung",
    description:
     "Wir können Ihnen helfen, eine maßgeschneiderte mobile App zu erstellen, die mit Ihrer Marke und Ihren Zielen übereinstimmt, mit Expertise in verschiedenen mobilen Plattformen.",
   },
   s6: {
    title: "Testdienst",
    description:
     "Mit unserer Kompetenz in Testdienstleistungen sind wir engagiert, Ihnen bei der Identifizierung und Implementierung der effektivsten Testlösungen zu helfen, die auf Ihre spezifischen Anforderungen und Ziele zugeschnitten sind.",
   },
   s7: {
    title: "IT-Beratung & Beratung",
    description:
     "Die richtige Technologie, richtig implementiert, angemessen verwaltet und überwacht, kann zu erheblichen Wachstumsvorteilen führen.",
   },
  },
 },
 footer: {
  heading: {
   about: "Über Uns",
   link1: "Startseite",
  },
  quickLink: {
   heading: "Snabblänkar",
   link1: "Häufig gestellte Fragen",
   link2: "Sekretesspolicy",
  },
  contact: {
   heading: "Kontakta Oss",
   email: "info@owision.com",
   phone: "+46-84527113",
  },
  getintouch: "Kontakt aufnehmen",

  copyright: "Urheberrecht © 2024 Owision - Alla Rättigheter Förbehållna.",
 },
 about: {
  heading: "Engagiert, Exzellenz zu liefern",
  subheading: "Jeder hat eine Geschichte. Hier ist unsere.",
  ourStory: {
   s1: "Willkommen bei Owision, wo wir uns auf die Entwicklung bahnbrechender Softwarelösungen für transformative digitale Erlebnisse spezialisiert haben. Unser Engagement für Exzellenz treibt uns an, die technologischen Grenzen zu erweitern.",
   s2: "Unser kundenorientierter Ansatz unterscheidet uns von anderen. Wir erkennen die Einzigartigkeit jedes Unternehmens an und gehen auf die Bedürfnisse, Herausforderungen und Ziele unserer Kunden ein. Dieser maßgeschneiderte Ansatz ermöglicht es uns, individuell angepasste Softwarelösungen für maximale Effizienz und Wirkung zu schaffen.",
   s3: "Wir bringen Fachwissen in den Bereichen Fintech, Telekommunikation und Gaming mit. Ob Sie ein Startup sind, das auf Skalierbarkeit abzielt, ein Unternehmen in der digitalen Transformation oder etwas dazwischen, wir haben das Wissen und die Erfahrung, um Ihre Vision zu verwirklichen.",
   s4: "Owision ist bestrebt, langfristige Partnerschaften zu pflegen. Über die Bereitstellung von Software hinaus streben wir danach, Ihr strategischer Verbündeter auf dem Weg zum Erfolg zu sein. Entdecken Sie die Möglichkeiten mit Owision, wo Innovation auf Exzellenz trifft.",
   s5: "Owision ist ein Team von einfachen, ehrlichen und intelligenten Menschen, die leidenschaftlich daran interessiert sind, Möglichkeiten zu identifizieren, ein gutes Unternehmen großartig zu machen! Wir schätzen die Beziehungen zu unseren Kunden, Mitarbeitern und Lieferanten als Maßstab für unseren Erfolg.",
  },
  mission: {
   heading: "Unsere Mission",
   p: "Bei Owision werden wir von einer Kernmission angetrieben – Unternehmen jeder Größe mit den technologischen Werkzeugen auszustatten, die sie benötigen, um in einem dynamischen Markt erfolgreich zu sein. Unser Team aus erfahrenen Entwicklern, Designern und Strategen arbeitet nahtlos zusammen, um Softwarelösungen zu schaffen, die nicht nur die Erwartungen unserer Kunden erfüllen, sondern übertreffen.",
  },
 },

 contactUs: {
  heading: "Bereit zu",
  span1: "Verbinden, Zusammenarbeiten und Exzellenz Schaffen?",
  span2: "Wir sind nur einen Klick entfernt!",
  contactDetails: {
   call: {
    heading: "Rufen Sie uns an",
    p: "Mo bis Fr (10:00 – 19:00)",
    button: "+46-84527113",
   },
   email: {
    heading: "Senden Sie eine E-Mail",
    p: "Antwort innerhalb eines Werktages.",
    button: "info@owision.com",
   },
   meeting: {
    heading: "Buchen Sie ein Meeting",
    p: "30 Minuten Online-Anruf",
    button: "Meeting buchen",
   },
  },
  form: {
   heading: "Füllen Sie einfach diese Details aus",
   subheading: "Wir werden uns innerhalb von 24 Stunden bei Ihnen melden.",
   name: { title: "Name", placeholder: "John Doe" },
   email: { title: "E-Mail-Adresse", placeholder: "Ihre E-Mail-Adresse" },
   phone: {
    title:
     "Geben Sie Ihre Telefonnummer ein (Bitte auch die Ländervorwahl eingeben)",
    placeholder: "Tel: +1 718 303 2844",
   },
   msg: {
    title: "Schreiben Sie Ihre Nachricht unten",
    placeholder: "Ihre Nachricht",
   },
   button: "SENDEN",
  },
 },
 faqHeading: "Häufig gestellte Fragen",
 faq: [
  {
   question:
    "Welche Dienstleistungen bietet Ihr Softwareentwicklungsunternehmen an?",
   answer:
    "Wir bieten ein umfassendes Leistungsspektrum an, darunter maßgeschneiderte Softwareentwicklung, mobile App-Entwicklung, Webentwicklung und Softwareberatung.",
  },
  {
   question: "Wie erfahren ist Ihr Entwicklungsteam?",
   answer:
    "Unser Entwicklungsteam besteht aus hochqualifizierten und erfahrenen Fachleuten mit Expertise in verschiedenen Technologien und Branchen.",
  },

  {
   question: "Können Sie mit bestimmten Technologien oder Frameworks arbeiten?",
   answer:
    "Ja, wir sind versiert in einer Vielzahl von Technologien und Frameworks. Unser Team ist anpassungsfähig und kann mit den Technologien arbeiten, die am besten zu Ihren Projektanforderungen passen.",
  },
  {
   question: "Auf welche Branchen sind Sie spezialisiert?",
   answer:
    "Wir haben Erfahrung in verschiedenen Branchen, darunter Gesundheitswesen, Finanzen, E-Commerce, Bildung und mehr.",
  },
  {
   question: "Wie stellen Sie die Sicherheit unseres Projekts sicher?",
   answer:
    "Sicherheit hat für uns oberste Priorität. Wir implementieren bewährte Verfahren der Branche, führen regelmäßige Sicherheitsprüfungen durch und halten uns an strenge Datenschutzmaßnahmen.",
  },

  {
   question:
    "Wie sieht der typische Entwicklungsprozess Ihres Unternehmens aus?",
   answer:
    "Unser Entwicklungsprozess umfasst eine detaillierte Projektanalyse, Design, Entwicklung, Tests, Bereitstellung und laufende Unterstützung. Wir folgen agilen Methoden für Flexibilität und Kundeneinbindung.",
  },
  {
   question:
    "Können Sie sowohl kleine Projekte als auch groß angelegte Unternehmenslösungen bewältigen?",
   answer:
    "Absolut. Wir betreuen Projekte jeder Größe und stellen sicher, dass unsere Lösungen den spezifischen Bedürfnissen und Zielen unserer Kunden entsprechen.",
  },
  {
   question: "Wie handhaben Sie Projektmanagement und Kommunikation?",
   answer:
    "Wir verwenden robuste Projektmanagement-Tools und pflegen transparente Kommunikationskanäle, um die Kunden über den Projektfortschritt zu informieren.",
  },
  {
   question:
    "Wie lange dauert es im Durchschnitt, ein Softwareprojekt abzuschließen?",
   answer:
    "Die Projektlaufzeiten variieren je nach Komplexität und Umfang. Wir bieten detaillierte Zeitpläne während der Erstberatung.",
  },
  {
   question:
    "Wie gehen Sie mit Änderungen oder Aktualisierungen während des Entwicklungsprozesses um?",
   answer:
    "Wir begrüßen Feedback und Änderungen während des gesamten Entwicklungsprozesses. Wir folgen einem iterativen Ansatz und stellen sicher, dass wir flexibel auf die Anforderungen der Kunden eingehen können.",
  },

  {
   question:
    "Bieten Sie nach Abschluss des Projekts laufende Wartung und Support an?",
   answer:
    "Ja, wir bieten laufende Wartung und Support an, um den reibungslosen Betrieb Ihrer Software sicherzustellen und etwaige Probleme schnell zu beheben.",
  },
  {
   question:
    "Können Sie uns bei der Integration der neuen Software in bestehende Systeme unterstützen?",
   answer:
    "Absolut. Wir haben Erfahrung in der nahtlosen Integration, um sicherzustellen, dass die neue Software Ihre bestehenden Systeme ergänzt und verbessert.",
  },
  {
   question: "Wie stellen Sie die Qualität der Software sicher?",
   answer:
    "Qualitätssicherung ist ein integraler Bestandteil unseres Entwicklungsprozesses. Wir führen gründliche Tests in jeder Phase durch, um etwaige Probleme sofort zu identifizieren und zu beheben.",
  },

  {
   question:
    "Was passiert, wenn nach Abschluss des Projekts Probleme auftreten?",
   answer:
    "Wir bieten Support nach dem Start an, um etwaige Probleme zu beheben. Unser Team ist bestrebt, den langfristigen Erfolg Ihrer Software sicherzustellen.",
  },
  {
   question:
    "Können Sie Referenzen oder Fallstudien zu Ihren früheren Projekten bereitstellen?",
   answer:
    "Selbstverständlich. Auf Anfrage können wir Referenzen und Fallstudien vorlegen, um unsere erfolgreichen Kooperationen zu präsentieren.",
  },
  {
   question: "Wie gehen Sie mit geistigem Eigentum und Vertraulichkeit um?",
   answer:
    "Wir legen großen Wert auf die Vertraulichkeit unserer Kunden und halten uns an strenge Maßnahmen zum Schutz geistigen Eigentums. Wir sind bereit, Vertraulichkeitsvereinbarungen (NDAs) zu unterzeichnen, falls erforderlich.",
  },

  {
   question: "Wie sieht Ihr Preismodell aus?",
   answer:
    "Unser Preismodell ist auf die spezifischen Anforderungen jedes Projekts zugeschnitten. Wir bieten transparente und detaillierte Kostenschätzungen während der Erstberatung.",
  },
  {
   question:
    "Wie gehen Sie mit Änderungen im Projektumfang oder den Anforderungen um?",
   answer:
    "Wir verstehen, dass sich der Projektumfang entwickeln kann. Wir arbeiten eng mit den Kunden zusammen, um Änderungen zu berücksichtigen und Zeitpläne sowie Kosten entsprechend anzupassen.",
  },
  {
   question: "Wie gestalten Sie das Nutzererlebnis (UX)?",
   answer:
    "Wir legen großen Wert auf nutzerzentriertes Design und stellen sicher, dass unsere Lösungen eine intuitive und angenehme Erfahrung für die Endnutzer bieten.",
  },
  {
   question: "Wie können wir mit einem Projekt beginnen?",
   answer:
    "Kontaktieren Sie uns einfach über unsere Website oder direkt. Wir vereinbaren ein erstes Gespräch, um Ihre Projektziele, Anforderungen und die nächsten Schritte zu besprechen.",
  },
 ],
 sap: {
  intro: {
   heading: "Einführung in SAP",
   desc:
    "SAP ist ein weltweit führender Anbieter von Unternehmenssoftwarelösungen, die darauf ausgelegt sind, Geschäftsprozesse zu erleichtern und Wachstum in verschiedenen Sektoren zu fördern. Mit seiner umfassenden Suite von Dienstleistungen erfüllt SAP eine Vielzahl von Geschäftsbedürfnissen durch seine Module in Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Supply Chain Management (SCM), Human Resources (HR) und mehr. Egal, ob Sie ein kleines Unternehmen oder ein großes Unternehmen sind, SAP bietet maßgeschneiderte Lösungen, die dazu beitragen, Ihre Prozesse zu optimieren, Kundenbeziehungen zu verbessern und datengestützte Erkenntnisse für strategische Entscheidungen zu nutzen.",
  },
  module: {
   heading: "Wichtige Module von SAP",
   erp: {
    span: "SAP ERP :",
    li: "Integriert zentrale Geschäftsbereiche wie Finanzen, Vertrieb, Einkauf und Produktion in eine einheitliche Plattform, verbessert die Echtzeittransparenz und unterstützt effektives Entscheidungsmanagement.",
   },
   crm: {
    span: "SAP CRM",
    li: "Verbessert das Kundenengagement und den Vertrieb durch die Verwaltung von Interaktionen über verschiedene Kanäle, was die Kundenzufriedenheit und die Unternehmensleistung steigert.",
   },
   scm: {
    span: "SAP SCM :",
    li: "Optimiert die Lieferkettenoperationen mit Werkzeugen für effiziente Bedarfs- und Angebotsplanung, Lagerverwaltung und Logistik.",
   },
   hr: {
    span: "SAP HR :",
    li: "Verwaltet HR-Prozesse einschließlich Gehaltsabrechnung, Talentmanagement und Personalplanung, um Talente zu gewinnen, zu entwickeln und zu halten.",
   },
   bi: {
    span: "SAP BI :",
    li: "Wandelt Daten in umsetzbare Erkenntnisse durch umfassende Berichterstattung, Analyse und Visualisierungstools um und unterstützt umfassende Geschäftsanalysen.",
   },
   more: {
    span: "Und viele weitere :",
    li: "Einschließlich SAP HCM, SAP SRM, SAP EWM, SAP PLM und SAP EHS, die jeweils spezifische Geschäftsbedürfnisse abdecken und die betriebliche Effizienz verbessern.",
   },
  },
  plan: {
   heading: "Unser SAP-Implementierungsplan",
   desc:
    "Wir beginnen jedes SAP-Projekt mit einer gründlichen Bewertung Ihrer Geschäftsanforderungen, Ziele und aktuellen Systeme, um unser Vorgehen anzupassen. Unser Implementierungsprozess umfasst:",
   li1: "Detaillierte Projektplanung mit klaren Meilensteinen und Zeitplänen.",
   li2: "Umfassende Teambildung mit Beratern, Entwicklern und Beteiligten.",
   li3: "Anpassung der SAP-Funktionalitäten an die spezifischen Geschäftsanforderungen.",
   li4: "Datenmigration von Altsystemen zur SAP-Plattform.",
   li5: "Gründliche Tests zur Sicherstellung der Funktionalität und Leistung.",
   li6: "Schulung und Unterstützung der Benutzer zur Maximierung der Systemnutzung und Effizienz.",
   li7: "Phasenweise Einführung zur Minimierung der betrieblichen Störungen.",
  },
  implement: {
   heading: "Warum sollten Sie uns für Ihre SAP-Implementierung wählen?",
   p: "Unsere tiefgreifende Expertise in SAP-Technologien und eine nachweisliche Erfolgsbilanz bei erfolgreichen Implementierungen machen uns zum idealen Partner für Ihr SAP-Projekt. Wir legen großen Wert auf die Zusammenarbeit mit unseren Kunden und sind bestrebt, Ihre Geschäftsprozesse durch innovative SAP-Lösungen zu transformieren. Durch die Wahl von uns profitieren Sie von:",
   li1: "Einem erfahrenen Expertenteam, das sich von Anfang bis Ende Ihrem Projekt widmet.",
   li2: "Maßgeschneiderten Lösungen, die auf Ihre spezifischen Geschäftsbedürfnisse abgestimmt sind.",
   li3: "Einem Fokus auf die Lieferung konkreter Geschäftsergebnisse und einer hohen Rendite auf die Investition.",
   li4: "Kontinuierlicher Unterstützung und Optimierung Ihrer SAP-Systeme nach der Implementierung.",
  },
  whySpa: {
   heading: "Warum SAP?",
   desc:
    "Die unvergleichliche Kombination von umfassenden Fähigkeiten und innovativen Lösungen macht SAP zur bevorzugten Wahl für Unternehmen, die ihre Betriebsabläufe zukunftssicher machen möchten. Mit SAPs breitem Spektrum an Modulen können Unternehmen ihre Prozesse optimieren, die digitale Transformation vorantreiben und sich im dynamischen Marktumfeld einen Wettbewerbsvorteil verschaffen.",
  },
  competitors: {
   heading: "Wettbewerber von SAP",
   desc:
    "SAP tritt gegen mehrere andere große Akteure auf dem Markt für Unternehmenssoftware an, darunter Oracle, Microsoft Dynamics 365 und Salesforce, die jeweils einzigartige Stärken und Schwerpunkte bieten. Die Wahl zwischen SAP und seinen Wettbewerbern hängt von spezifischen Geschäftsbedürfnissen, Branchenanforderungen und Budgetüberlegungen ab.",
  },
  resolution: {
   heading: "Lösung",
   desc:
    "Nutzen Sie die umfassende Suite von Unternehmenssoftwarelösungen von SAP, um Effizienz, Wachstum und Erfolg in Ihrer Organisation voranzutreiben. Mit unserer Expertenberatung und umfassenden Unterstützung helfen wir Ihnen, Ihre SAP-Reise zu navigieren, um optimale Ergebnisse und eine bedeutende Rendite auf Ihre Investition zu erzielen. Nutzen Sie die Kraft von SAP mit uns und verwandeln Sie Ihr Unternehmen in ein agileres, aufschlussreicheres und effizienteres Unternehmen.",
  },
 },
 privacy: {
  heading: "Datenschutzrichtlinie",
  sub: "für Owision AB",
  p1: "Diese Datenschutzrichtlinie betrifft Owision AB und ihre Website:",
  p2: "Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln",
  cookies: {
   heading: "Cookies",
   desc:
    "Ein 'Cookie' ist eine kleine Textdatei, die auf einem Computer zu Aufzeichnungszwecken gespeichert wird. Wir verwenden Cookies zur Analyse von Trends, zur Verwaltung der Website, zur Verfolgung der Bewegungen von Benutzern und zur Erfassung demografischer Informationen über unsere Benutzerbasis insgesamt. Einige Cookies bleiben auf Ihrem Computer, bis Sie sie löschen. Andere, wie Session-ID-Cookies, verfallen, wenn Sie Ihren Browser schließen. Sie können Ihren Browser so einstellen, dass er Cookies ablehnt, und Sie können den Dienst dennoch nutzen. Bestimmte Funktionen des Dienstes sind jedoch möglicherweise nicht verfügbar, wenn Ihr Browser keine Cookies akzeptiert. Diese Website verwendet Google Analytics, das Webverkehrsdaten sammelt, speichert und analysiert, um zu verstehen, wie Menschen die Website nutzen.",
  },
  pixels: {
   heading: "Pixel",
   desc:
    "'Pixel' sind kleine Grafiken mit einer eindeutigen Kennung, die verwendet werden, um die Online-Bewegungen von Webbenutzern zu verfolgen. Im Gegensatz zu Cookies, die auf der Festplatte eines Computers gespeichert werden, sind Pixel kleine Grafiken, die etwa so groß wie der Punkt am Ende eines Satzes sind und unsichtbar in Webseiten oder HTML-basierte E-Mails eingebettet sind. Unsere Drittanbieter von Analysediensten können Pixel auf der Website platzieren, die verfolgen, welche anderen Websites Sie besuchen (sowohl vor als auch nach dem Besuch der Website). Unsere Drittanbieter von Analysediensten verwenden die von Pixeln erhaltenen Informationen, um uns zu helfen, unser Geschäft und den Dienst zu verbessern. Wir haben keine Kontrolle über die Verwendung von Pixeln durch Dritte.",
  },
  thirdParty: {
   heading: "Drittanbieter von Analysediensten",
   desc:
    "Wir nutzen Dritte, um uns bei der Verwaltung und Verbesserung des Dienstes zu unterstützen. Dabei können wir diesen Dritten nicht personenbezogene Informationen über die Nutzung des Dienstes durch die Benutzer mitteilen. Diese Drittanbieter verwenden ebenfalls Cookies. Wir nutzen Drittanbieter von Analysediensten, wie Google Analytics, um uns allgemeine demografische und interessensbasierte Informationen über unsere Benutzer bereitzustellen und um eine bessere Benutzererfahrung zu schaffen. Wir haben keine Kontrolle über die von Dritten gesammelten Informationen und sind nicht für deren Verwendung verantwortlich.",
  },
  embedded: {
   heading: "Eingebettete Inhalte von anderen Websites",
   desc:
    "Artikel auf dieser Website können eingebettete Inhalte enthalten (z. B. Videos, Bilder, Artikel usw.). Eingebettete Inhalte von anderen Websites verhalten sich genauso, als ob der Besucher die andere Website besucht hätte. Diese Websites können Daten über Sie sammeln, Cookies verwenden, zusätzliche Drittanbieter-Tracking einbetten und Ihre Interaktion mit diesen eingebetteten Inhalten überwachen, einschließlich der Nachverfolgung Ihrer Interaktion mit den eingebetteten Inhalten, wenn Sie ein Konto haben und auf dieser Website angemeldet sind.",
  },
  analytics: {
   heading: "Analysen",
   desc:
    "Diese Website verwendet Google Analytics, das Webverkehrsdaten sammelt, speichert und analysiert, um zu verstehen, wie Menschen die Website nutzen. Google Analytics verwendet Cookies und Pixel, um demografische und interessensbasierte Informationen sowie Nutzungsinformationen von Benutzern zu sammeln, die den Dienst besuchen, einschließlich, aber nicht beschränkt auf Informationen über die Seiten, auf denen Benutzer in den Dienst ein- und aussteigen, welche Seiten Benutzer auf dem Dienst anzeigen, Verweildauer, Browser, Betriebssystem und IP-Adresse. Cookies und Pixel ermöglichen es Google, einen Benutzer zu erkennen, wenn er den Dienst besucht und wenn er andere Websites besucht. Google verwendet die Informationen, die es vom Dienst und anderen Websites sammelt, um uns und anderen Website-Betreibern Informationen über Benutzer bereitzustellen, einschließlich, aber nicht beschränkt auf Altersgruppen, Geschlecht, geografische Regionen, allgemeine Interessen und Details zu Geräten, die zum Besuch von Websites und zum Kauf von Artikeln verwendet werden. Wir ergreifen angemessene Maßnahmen, um eine Verknüpfung der von uns von Google erhaltenen Informationen mit Ihren personenbezogenen Daten zu verhindern. Für weitere Informationen zur Verwendung von Cookies durch Google und zur Erhebung und Nutzung von Informationen siehe ",
   link: "Google-Datenschutzrichtlinie",
  },
  p3: "Welche Rechte Sie über Ihre Daten haben",
  p4: "Wenn Sie ein Konto auf dieser Website haben oder Kommentare hinterlassen haben, können Sie eine exportierte Datei der personenbezogenen Daten anfordern, die wir über Sie gespeichert haben, einschließlich aller Daten, die Sie uns zur Verfügung gestellt haben. Sie können auch verlangen, dass wir alle personenbezogenen Daten, die wir über Sie gespeichert haben, löschen. Dies schließt keine Daten ein, die wir aus administrativen, rechtlichen oder sicherheitsrelevanten Gründen aufbewahren müssen.",
  p5: "Besucherdaten können über einen automatischen Spam-Erkennungsdienst überprüft und an Drittprodukte gesendet werden.",
 },
};
